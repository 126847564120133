import React from 'react';
import { getBreadcrumbObjectForComponentsLink } from './pageTitleUtils';
import translate from '@data/translations';
import useStore from '@data/state/zustand';

function PageTitle(props: any) {
    const pageTitle: string = useStore((state) => state.pageTitle);
    const pagePath: string = useStore((state) => state.pagePath);
    let breadCrumbArr = [];
    if (pagePath) {
        breadCrumbArr = getBreadcrumbObjectForComponentsLink(pagePath);
    }

    if (props.alternate) {
        return (
            <div>
                <h1 className="page-title" style={{paddingBottom: props.classPaddingBottom ? "1px" : ""}}>{props.title || pageTitle}</h1>
            </div>
        );
    } else {
        return (
            <div>
                <h1 className="page-title" style={{paddingBottom: props.classPaddingBottom ? "1px" : ""}}>
                    <div className="breadcrumb-wrapper d-print-none">
                        {breadCrumbArr.map((obj, ind) => {
                            if (ind === breadCrumbArr.length - 1) {
                                return (
                                    <span key={ind}>
                                        <span>{translate(obj.title)}</span>
                                    </span>
                                );
                            } else {
                                return (
                                    <span key={ind}>
                                        <a href={'#' + obj.link}>{translate(obj.title)}</a>
                                        {' / '}
                                    </span>
                                );
                            }
                        })}
                    </div>
                    {props.title || pageTitle}
                </h1>
            </div>
        );
    }
}

export default PageTitle
