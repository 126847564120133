import React from 'react';
import translate from '@data/translations';
import { PersistentNotificationSystemModel } from '@common/modelDefinition';

// enum SeverityEnumColor {
//     '#fed4ae',
//     '#fc8518',
//     '#ec1010',
// }

interface PersistentNotificationSystemContainerProps {
    persistentNotificationSystems: PersistentNotificationSystemModel[];
}

interface ObjectKey {
    [key: string]: PersistentNotificationSystemModel[];
}

const PersistentNotificationSystemContainer = (props: PersistentNotificationSystemContainerProps) => {
    const { persistentNotificationSystems } = props;

    // Grupiramo notifikacije po polju "title"
    const groupedNotifications = persistentNotificationSystems.reduce(
        (acc: ObjectKey, persistentNotificationSystem: PersistentNotificationSystemModel) => {
            const { title } = persistentNotificationSystem;

            if (!acc[title]) {
                acc[title] = [];
            }
            acc[title].push(persistentNotificationSystem);
            acc[title].sort((a, b) => b.severityEnum - a.severityEnum);
            return acc;
        },
        {}
    );

    return (
        <div className="persistent-notification-system-container">
            {Object.keys(groupedNotifications).map((title, index) => (
                <div className="persistent-notification-system-group" key={index}>
                    <div className="persistent-notification-system-group-title">{title}</div>
                    {groupedNotifications[title].map((persistentNotificationSystem) => {
                        let icon, backgroundColor, color;
                        switch (persistentNotificationSystem.severityEnum) {
                            case 0:
                                icon = '⚠️';  // Critical
                                backgroundColor = '#0000FF';
                                color = '#FFFFFF';
                                break;
                            case 1:
                                icon = '🚨';  // Warning
                                backgroundColor = '#FFA500';
                                color = '#FFFFFF';
                                break;
                            default:
                                icon = 'ℹ️';  // Info
                                backgroundColor = '#FF0000';
                                color = '#FFFFFF';
                        }
                        return (
                            <div
                                className="persistent-notification-system"
                                key={persistentNotificationSystem.id}
                                style={{ backgroundColor, color }}
                            >
                                 <span className="icon">{icon}</span>
                                <div className="persistent-notification-system-message">
                                    {translate(persistentNotificationSystem.message)}
                                </div>
                            </div>
                        );
                    })}
                </div>
            ))}
        </div>
    );
};

export default PersistentNotificationSystemContainer;
