import * as React from 'react';
import { Reservation, ReservationController } from '../resController';
import { translate } from '@data/translations';
import { AccommodationPricelistModel, ReservationModel } from '@common/modelDefinition';
import useStore from '@data/state/zustand';

interface OpenPriceProps {
    reservation: Reservation;
    isPending?: boolean;
}

const OpenPrice = (props: OpenPriceProps) => {
    const { reservation } = props;
    const [localOpenPrice, setLocalOpenPrice] = React.useState<number | null>();

    const reservations: Reservation[]  = useStore((appState: any) => appState.state.reservationForm2.reservations);
    const accommodationPricelists: AccommodationPricelistModel[] = useStore((appState: any) => appState.monkeys.filteredAccommodationPriceLists());

    React.useEffect(() => {
        if (reservation) {
            const { openPrice } = reservation;
            if (openPrice) {
                setLocalOpenPrice(openPrice);
            } else {
                const roomInfo = reservation.RoomInfo;
                if (roomInfo.accommodationPriceListId) {
                    const { adultsNumber } = reservation;
                    const targetPriceList = accommodationPricelists?.find(
                        (a: AccommodationPricelistModel) => a.id === roomInfo.accommodationPriceListId
                    );
                    let adultsNumberPriceIndex = adultsNumber === 0 ? 0 : adultsNumber - 1;
                    const accomodationPriceRules =
                        targetPriceList && targetPriceList.accomodationPriceRules
                            ? typeof targetPriceList.accomodationPriceRules === 'string'
                                ? JSON.parse(targetPriceList.accomodationPriceRules)
                                : targetPriceList.accomodationPriceRules
                            : [];
                    if (adultsNumber >= accomodationPriceRules.length) {
                        adultsNumberPriceIndex = accomodationPriceRules.length - 1;
                    }
                    const _openPrice = accomodationPriceRules[adultsNumberPriceIndex];
                    setLocalOpenPrice(_openPrice);
                }
            }
        }
    }, [reservation, accommodationPricelists]);
    const ctrl = new ReservationController();

    const updateGroupResOpenPrice = (price: number | null) => {
        if (reservations && reservations?.length > 1) {
            for (let i = 0; i < reservations.length; i++) {
                if (
                    reservations[i] &&
                    !reservations[i].id &&
                    !reservations[i].accommodationPriceListId &&
                    !reservations[i].openPrice
                ) {
                    ctrl.setActiveReservationIndex(i);
                    const res = { ...reservations[i] };
                    res.openPrice = price;
                    ctrl.updateActiveReservation(res as ReservationModel);
                }
            }
        }
    };

    if (!reservation) {
        return null;
    }

    return (
        <div className="">
            <label className="text-bold ml-4p mb-4p mt-2p">{translate('Custom price (opt.)')}</label>
            <input
                disabled={reservation.canceledReservationInvoicingStatus === 2}
                className={
                    reservation.canceledReservationInvoicingStatus !== 1 ? 'input input-modal ' : 'input input-modal '
                }
                placeholder={translate(
                    reservation.canceledReservationInvoicingStatus === 1 ? 'Cancellation fee' : 'Room price'
                )}
                autoComplete="off"
                type="number"
                value={localOpenPrice ? Number(Number(localOpenPrice).toFixed(4)) : ''}
                onChange={(e: any) => {
                        setLocalOpenPrice(e.target.value);
                }}
                onKeyPress={(event) => {
                    if (event.key === 'Enter') {
                        const res = { ...reservation };
                        if (localOpenPrice !== undefined) {
                            res.openPrice = localOpenPrice;
                            ctrl.updateActiveReservation(res);
                            updateGroupResOpenPrice(Number(localOpenPrice));
                        }
                    }
                }}
            />
            {window.innerWidth < 768 &&  localOpenPrice ? (
                <div
                    onClick={() => {
                        const res = { ...reservation };
                        if (localOpenPrice !== undefined) {
                            res.openPrice = localOpenPrice;
                            ctrl.updateActiveReservation(res);
                            updateGroupResOpenPrice(localOpenPrice);
                        }
                    }}
                    className="button-primary mt-8p"
                >
                    {translate('Apply')}
                </div>
            ) : null}
        </div>
    );
};

export default OpenPrice;
