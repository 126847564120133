import * as React from 'react';
import PageTitle from '@components/pageTitle';
import './style.css';
import moment from 'moment';
import 'moment/min/locales.min';
import { getFinancialJournalDetails } from '@pages/posTerminal/sideMenu/api';
import translate from '@data/translations';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import {
    InvoiceConclusionDetailsHeader,
    InvoiceConclusionDetailsList,
    SelectedTypeOrSubTypeByPosTerminalId,
} from '@pages/posTerminal/sideMenu/invoiceConclusion';
import { startGlobalLoading, stopGlobalLoading } from '@components/loaders/globalLoading';
//@ts-ignore
import { formatDate, parseDate } from 'react-day-picker/moment';
import { InvoiceConclusionDetailsByPosTerminalId } from '../../common/modelDefinition';
import useStore from '@data/state/zustand';

const FinancialJournal = (props: any) => {
    const [invoiceConclusionDetailsByPosTerminalId, setInvoiceConclusionDetailsByPosTerminalId] =
        React.useState<InvoiceConclusionDetailsByPosTerminalId | null>(null);
    const [from, setFromDate] = React.useState(null as number | null);
    const [to, setToDate] = React.useState(null as number | null);
    const [year, setYear] = React.useState(new Date().getFullYear() as number | null);
    const [month, setMonth] = React.useState(null as number | null);

    const [fromRange, setFromRangeDate] = React.useState(new Date().getTime() as number | null);
    const [toRange, setToRangeDate] = React.useState(new Date().getTime() as number | null);
    const [invoiceConclusionDetailsByPluByPosTerminalId, setInvoiceConclusionsDetailsByPluByPosTerminalId] =
        React.useState<InvoiceConclusionDetailsByPosTerminalId | null>(null);
    const [selectedTypeOrSubType, setSelectedTypeOrSubType] =
        React.useState<SelectedTypeOrSubTypeByPosTerminalId | null>(null);

    const systemLocale: string = useStore((appState: any) => appState.locale);
    const defaultCurrency: string = useStore((appState: any) => appState.defaultCurrency);
    const otherSettings = useStore((appState: any) => appState.otherSettings);

    React.useEffect(() => {
        const loadData = async () => {
            startGlobalLoading();
            const _invoiceConclusionsDetails = await getFinancialJournalDetails({
                group: 'type',
                from: from
                    ? new Date(from).setHours(12)
                    : fromRange
                    ? new Date(fromRange).setHours(12)
                    : new Date().setHours(12),
                to: to ? new Date(to).setHours(12) : toRange ? new Date(toRange).setHours(12) : new Date().setHours(12),
            });
            stopGlobalLoading();

            if (_invoiceConclusionsDetails) {
                setInvoiceConclusionDetailsByPosTerminalId(_invoiceConclusionsDetails);
                setInvoiceConclusionsDetailsByPluByPosTerminalId(null);
                setSelectedTypeOrSubType(null);
            }
        };

        loadData().catch((err) => {
            console.log(err);
        });
        // eslint-disable-next-line
    }, [from, to, year, month, fromRange, toRange]);

    React.useEffect(() => {
        const loadData = async () => {
            if (selectedTypeOrSubType && !invoiceConclusionDetailsByPluByPosTerminalId) {
                startGlobalLoading();
                const _invoiceConclusionsDetails = await getFinancialJournalDetails({
                    group: 'plu',
                    from: from
                        ? new Date(from).setHours(12)
                        : fromRange
                        ? new Date(fromRange).setHours(12)
                        : new Date().setHours(12),
                    to: to
                        ? new Date(to).setHours(12)
                        : toRange
                        ? new Date(toRange).setHours(12)
                        : new Date().setHours(12),
                });
                stopGlobalLoading();

                if (_invoiceConclusionsDetails) {
                    setInvoiceConclusionsDetailsByPluByPosTerminalId(_invoiceConclusionsDetails);
                }
            }
        };
        loadData().catch((err) => {
            console.log(err);
        });
        // eslint-disable-next-line
    }, [selectedTypeOrSubType, invoiceConclusionDetailsByPluByPosTerminalId, from, to, fromRange, toRange]);

    const YearSelect = (props: any) => {
        const { _year, setYear } = props;
        let yearOptions = [];
        for (let i = _year - 2; i <= new Date().getFullYear(); i++) {
            yearOptions.push(
                <option key={i} value={i}>
                    {i}
                </option>
            );
        }
        return (
            <select
                className="form-control form-control-sm px-1 w-auto  bg-white"
                value={year ? year : ''}
                onChange={(e: any) => {
                    setYear(Number(e.target.value));
                    if (month) {
                        const firstDay = getFirstDayOfMonth(Number(e.target.value), Number(month) - 1);
                        const lastDay = getLastDayOfMonth(Number(e.target.value), Number(month) - 1);
                        setFromDate(moment(firstDay).startOf('day').valueOf());
                        setToDate(moment(lastDay).endOf('day').valueOf());
                    }
                }}
            >
                {yearOptions}
            </select>
        );
    };

    const getFirstDayOfMonth = (year: number, month: number) => {
        return new Date(year, month, 1);
    };

    const getLastDayOfMonth = (year: number, month: number) => {
        return new Date(year, month + 1, 0);
    };

    const MonthSelect = (props: any) => {
        const { setMonth } = props;
        let monthOptions = [];
        for (let i = 1; i <= 12; i++) {
            monthOptions.push(
                <option key={i} value={i}>
                    {moment()
                        .month(i - 1)
                        .format('MMMM')}
                </option>
            );
        }
        return (
            <select
                className="form-control form-control-sm px-1 w-auto bg-white ml-16p"
                value={month ? month : ''}
                onChange={(e: any) => {
                    if (e.target.value === 'byDate') {
                        setFromRangeDate(new Date().getTime());
                        setToRangeDate(new Date().getTime());
                        setYear(new Date().getFullYear());
                        setMonth(null);
                        setFromDate(null);
                        setToDate(null);
                    } else {
                        const firstDay = getFirstDayOfMonth(Number(year), Number(e.target.value) - 1);
                        const lastDay = getLastDayOfMonth(Number(year), Number(e.target.value) - 1);
                        setMonth(Number(e.target.value));
                        setFromDate(moment(firstDay).startOf('day').valueOf());
                        setToDate(moment(lastDay).endOf('day').valueOf());
                        setFromRangeDate(null);
                        setToRangeDate(null);
                    }
                }}
            >
                <option key={'byDate'} value="byDate">
                    {translate('By Date')}
                </option>
                {monthOptions}
            </select>
        );
    };

    return (
        <div className="mobile-page">
            <PageTitle classPaddingBottom={true}/>
            <small className='financial-journal-subtitle'>{translate("Based on shift start")}</small>
            <div className="d-flex">
                <YearSelect _year={new Date().getFullYear()} setYear={setYear} />
                <MonthSelect setMonth={setMonth} />
                {!month ? (
                    <div>
                        <DayPickerInput
                            inputProps={{
                                style: { width: '110px' },
                                className: ' form-control form-control-sm ml-16p ',
                            }}
                            value={fromRange ? new Date(fromRange) : new Date()}
                            format={otherSettings?.dateFormat ? otherSettings.dateFormat : 'DD/MM/YYYY'}
                            formatDate={formatDate}
                            parseDate={parseDate}
                            onDayChange={async (e: any) => {
                                setFromRangeDate(new Date(e).getTime());
                                if (toRange && new Date(e).getTime() > toRange) {
                                    setToRangeDate(new Date(e).getTime());
                                }
                            }}
                            dayPickerProps={{
                                disabledDays: { after: new Date() },
                            }}
                        />
                        <small>&ensp;{translate('-')}&ensp;</small>
                        <DayPickerInput
                            inputProps={{
                                style: { width: '110px' },
                                className: ' form-control form-control-sm ',
                            }}
                            value={toRange ? new Date(toRange) : new Date()}
                            format={otherSettings?.dateFormat ? otherSettings.dateFormat : 'DD/MM/YYYY'}
                            formatDate={formatDate}
                            parseDate={parseDate}
                            onDayChange={async (e: any) => {
                                setToRangeDate(new Date(e).getTime());
                            }}
                            dayPickerProps={{
                                disabledDays: {
                                    after: new Date(),
                                    before: fromRange ? new Date(fromRange) : new Date(),
                                },
                            }}
                        />
                    </div>
                ) : null}
            </div>

            <div className="d-flex mt-2" style={{ fontWeight: 'bold' }}>
                <div>{translate('Selected date(s):')}</div>{' '}
                <div className="ml-2">
                    {fromRange && toRange
                        ? moment(fromRange)
                              .locale(systemLocale)
                              .format(otherSettings?.dateFormat ? otherSettings.dateFormat : 'DD/MM/YYYY') +
                          ' - ' +
                          moment(toRange)
                              .locale(systemLocale)
                              .format(otherSettings?.dateFormat ? otherSettings.dateFormat : 'DD/MM/YYYY')
                        : from && to
                        ? moment(from)
                              .locale(systemLocale)
                              .format(otherSettings?.dateFormat ? otherSettings.dateFormat : 'DD/MM/YYYY') +
                          ' - ' +
                          moment(to)
                              .locale(systemLocale)
                              .format(otherSettings?.dateFormat ? otherSettings.dateFormat : 'DD/MM/YYYY')
                        : ''}
                </div>
            </div>

            <div>
                <div className="col-12 mt-2 mb-3">
                    <h5 className="mt-2 mb-2 pointer text-center p-2">
                        {<b>{translate('Invoiced').toUpperCase()}</b>}{' '}
                    </h5>
                </div>
                {invoiceConclusionDetailsByPosTerminalId ? (
                    Object.keys(invoiceConclusionDetailsByPosTerminalId).map((posTerminalName: string) => {
                        const invoiceConclusionDetail = invoiceConclusionDetailsByPosTerminalId[posTerminalName];

                        const invoiceConclusionDetailsByPlu = invoiceConclusionDetailsByPluByPosTerminalId
                            ? invoiceConclusionDetailsByPluByPosTerminalId?.[posTerminalName]
                                ? invoiceConclusionDetailsByPluByPosTerminalId[posTerminalName]
                                : null
                            : null;
                        if (invoiceConclusionDetail.invoiced && invoiceConclusionDetail.invoiced.length > 0) {
                            return (
                                <div className="mr-16p" key={posTerminalName}>
                                    <h3>{posTerminalName}</h3>
                                    <div>
                                        <InvoiceConclusionDetailsHeader />
                                        <InvoiceConclusionDetailsList
                                            invoiceConclusionDetails={invoiceConclusionDetail?.invoiced}
                                            defaultCurrency={defaultCurrency}
                                            invoiceConclusionDetailsByPlu={invoiceConclusionDetailsByPlu?.invoiced}
                                            setInvoiceConclusionsDetailsByPlu={
                                                setInvoiceConclusionsDetailsByPluByPosTerminalId
                                            }
                                            setSelectedTypeOrSubType={setSelectedTypeOrSubType}
                                            selectedTypeOrSubType={selectedTypeOrSubType}
                                        />
                                    </div>
                                </div>
                            );
                        } else {
                            return null;
                        }
                    })
                ) : (
                    <div className="text-center">{translate('There is no data for selected date(s).')}</div>
                )}
            </div>
        </div>
    );
};

export default FinancialJournal;
